import CallToAction from "components/CallToAction"
import { getStoryblokLink } from "utilities/getStoryblokLink"

export default function TwoButton({ blok }) {
  return (
    <div className="flex flex-col gap-x-6 gap-y-2 sm:flex-row">
      {blok.buttons.map((button) => (
        <CallToAction
          className="max-w-max whitespace-nowrap"
          key={button._uid}
          {...button}
          href={getStoryblokLink(button.link)}
        >
          {button.label}
        </CallToAction>
      ))}
    </div>
  )
}
